import React, {useEffect, useState} from "react";
import { Link } from "gatsby";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import ServiceAddress from "./new-pages/common/ServiceAddress";
import VideoSection from "./new-pages/common/VideoSection";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import LastSection from "./new-pages/common/LastSection";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import cowRightBottom from "../../static/images/home-cow.png";
import sec2Cow from "../../static/images/sec2-cow.jpg";
import tvImg3 from "../../static/images/tv-3.jpg";
import mapImg from "../../static/images/map-img.png";
import arrowRight from "../../static/images/arrow.png";
import { calculateYearlyPrice, transformReferralCodeToName } from "../customfunction";
import RatingReviewHome from "./new-pages/common/RatingReviewHome";
import CommonQuestionHome from "./new-pages/common/CommonQuestionHome";
import EasySteps from "./new-pages/common/EasySteps";
import { hideCouponHeader, useReferralState, bannerMessage } from "../ReferralWrapper"
import { useSiteMetadata } from "../hooks";
import axios from "axios";
import {calculateYearlyPricePerMonth, splitDescription} from "../utils";
import {productCatalogS3Bucket} from "../config";

const FrontHomePage = () => {
    const [tabType, setTabType] = React.useState(false);
    const [messagePopup, setMessagePopup] = React.useState(false);
    let referral = useReferralState()
    let banner_message = bannerMessage();
    let isServiceOutageMode = useSiteMetadata()
    const [showDetails1, setShowDetails1] = React.useState(false);
    const [showDetails3, setShowDetails3] = React.useState(false);
    const [internetPlans, setInternetPlans] = useState([]);
    const [tvPlans, setTvPlans] = useState([]);
    const [phonePlans, setPhonePlans] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const internetResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/internet/plans.json"
                );
                const tvResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/tv/plans.json"
                );
                const phoneResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/home-phones/plans.json"
                );
                setInternetPlans(internetResponse.data);
                setTvPlans(tvResponse.data);
                setPhonePlans(phoneResponse.data)
            } catch (err) {
                console.error("Failed to fetch internet plans:", err);
            }
        };
        fetchData();
    }, [])
    return (
        <div className="home-parent">
            <LayoutNew>
                <Helmet>
                    <title>Home – Purple Cow Internet 💜🐄</title>
                </Helmet>
                {referral && referral.length > 0 && !hideCouponHeader(referral) && (
                    <div className="top-0 px-4 py-3 text-lg font-thin tracking-widest text-center text-white bg-primary-700">
                        {banner_message ? (<>{banner_message}</>) : <>
                            Hey, I see you're friends with{" "}
                            <span className="font-normal">
                                {" " + transformReferralCodeToName(referral)}
                            </span>
                            . Awesome!
                        </>}
                    </div>
                )}
                <section className="home-section1">
                    <div className="container mx-auto px-4">
                        <div className="relative">
                            <h2 className="h2 text-white">Home of the $60 internet</h2>
                            <p className="p2 text-white my-7">Love our Internet or it's Free! Guaranteed!</p>
                            <div className="home-top-feild">
                                <input
                                    type="text"
                                    className="rounded-lg  focus:z-10 input1"
                                    name="line11"
                                    required
                                    placeholder="Service Address"
                                    id="home_new_address"
                                    onBlur={(e) => {
                                        setTimeout(() => {
                                            let ad: any = document.getElementById('address')
                                            if (ad !== null) {
                                                ad.value = '';
                                            }
                                            let unitField: any = document.getElementById('unitField')
                                            if (unitField !== null) {
                                                unitField.value = '';
                                            }
                                        }, 700)
                                    }}
                                />
                                <input
                                    type="text"
                                    className="rounded-lg  focus:z-10 input2 max-width80"
                                    name="line2"
                                    id="line2_address"
                                    placeholder={"Unit"}
                                    required
                                />
                            </div>
                            <Link className="btn-bg-new mt-5" onClick={(e) => {
                                let ad: any = document.getElementById('home_new_address');
                                if (!ad.value) {
                                    e.preventDefault()
                                }
                            }} to="/join-the-herd">Check Address <img src={arrowRight} alt="...." className="right-arrow" /></Link>
                        </div>
                        <img src={cowRightBottom} alt="cow" className="cow-right-bottom" />
                    </div>
                </section>

                <section className="home-section2-new">
                    <div className="container mx-auto px-4">
                        <h2 className="h2 text-secondary text-center">What's a Purple Cow?</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <img src={sec2Cow} alt="section cow" className="home-sec2-img" />
                            <div className="home-sec2-content">
                                <p className="p2">Based in Halifax, Nova Scotia, Purple Cow Internet is revolutionizing the ISP industry. Our mission is to dramatically reduce internet costs, creating value for everyone involved. </p>
                                <p className="p2 large-view-show">Consistently acclaimed, we've been named Halifax's top internet provider by Google reviews and The Coast. Additionally, our innovative approach has earned us multiple accolades, including the 'Best New Business of the Year' by the Halifax Chamber of Commerce.</p>

                                {showDetails1 && (
                                    <p className="p2">Consistently acclaimed, we've been named Halifax's top internet provider by Google reviews and The Coast. Additionally, our innovative approach has earned us multiple accolades, including the 'Best New Business of the Year' by the Halifax Chamber of Commerce.</p>
                                )}
                                <p className={showDetails1 ? 'p2 learn-more-mobile show-detail' : 'p2 learn-more-mobile '} onClick={() => setShowDetails1(!showDetails1)} >
                                    {showDetails1 ? 'Hide Details' : 'Learn More'}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="#fbbd69"
                                        strokeWidth={2}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <EasySteps
                    title="We make joining easy"
                    subtitle="just like being friendly"
                    paragraph="If a tech is required we can schedule one and it's completely free."
                />

                <section className="tv-section4">
                    <div className="container mx-auto px-4">
                        <h2 className="h2 text-secondary text-center">Simple pricing</h2>
                        <p className="p2 text-black text-center mb-8">And it doesn't go up after a year!</p>
                        <div className="tab-type new-tab">
                            <span onClick={() => setTabType(false)} className={tabType ? "tab-type__option " : "tab-type__option active"}>Pay Monthly</span>
                            <span onClick={() => setTabType(true)} className={tabType ? "tab-type__option active" : "tab-type__option "}>Pay Yearly</span>
                        </div>
                        <div className="selectPlan addon-plan-1">
                            {internetPlans.filter(obj => obj.status === "ACTIVE").map((plan, index) => (
                                index != 0 ? null : (
                                    <div className="selectPlan__optional">
                                        <label className="selectPlan__box phone-page">
                                            <input
                                                type="checkbox"
                                                name="selectPlan__"
                                                checked={false}
                                            />
                                            <div className="selectPlan__box-inner">
                                                <h3 className="h3">Internet</h3>
                                                <h2 className="h2"
                                                    dangerouslySetInnerHTML={{__html: !tabType ? '$' + internetPlans[0].billing[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + internetPlans[0].billing[0].monthly.price + '</del> $' + calculateYearlyPricePerMonth(internetPlans[0].billing[0].yearly.price) + ' <sup>/ month</sup>'}}>
                                                </h2>
                                                <ul className="plan-list">
                                                    {splitDescription(internetPlans[0].description).map((line, index) => (
                                                        <li key={index}>{line}</li>
                                                    ))}
                                                </ul>
                                                <Link to="/internet">
                                                    <span className="a-link-bold">
                                                        View Internet options
                                                    </span>
                                                </Link>
                                            </div>
                                        </label>
                                    </div>
                                )
                            ))}
                            {tvPlans.filter(obj => obj.status === "ACTIVE").map((plan, index) => (
                                index != 0 ? null : (
                                    <div className="selectPlan__optional">
                                        <label className="selectPlan__box phone-page">
                                            <input
                                                type="checkbox"
                                                name="selectPlan__"
                                                checked={false}
                                            />
                                            <div className="selectPlan__box-inner">
                                                <h3 className="h3">TV</h3>
                                                <h2 className="h2"
                                                    dangerouslySetInnerHTML={{__html: !tabType ? '$' + tvPlans[0].billing_period[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + tvPlans[0].billing_period[0].monthly.price + '</del> $' + calculateYearlyPricePerMonth(tvPlans[0].billing_period[0].yearly.price) + ' <sup>/ month</sup>'}}>
                                                </h2>
                                                <div className="chennels-box">
                                                    {tvPlans[0].featured_channels.map((channel) => ( /* slice(0, 6) gets the first 6 elements*/
                                                        <img src={channel.image_url} alt="channels" className="channel-icon"/>
                                                    ))}
                                                </div>

                                                <p className="p4">{tvPlans[0].description}</p>
                                                <Link to="/tv">
                                                <span className="a-link-bold">
                                                    View TV options
                                                </span>
                                                </Link>
                                            </div>
                                        </label>
                                    </div>
                                )
                            ))}
                            {phonePlans.filter(obj => obj.status === "ACTIVE").map((plan, index) => (
                                index != 0 ? null : (
                                    <div className="selectPlan__optional">
                                        <label className="selectPlan__box phone-page">
                                            <input
                                                type="checkbox"
                                                name="selectPlan__"
                                                checked={false}
                                            />
                                            <div className="selectPlan__box-inner">
                                                <h3 className="h3">Home Phone</h3>
                                                <h2 className="h2"
                                                    dangerouslySetInnerHTML={{__html: !tabType ? '$' + phonePlans[0].billing_period[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + phonePlans[0].billing_period[0].monthly.price + '</del> $' + calculateYearlyPricePerMonth(phonePlans[0].billing_period[0].yearly.price) + ' <sup>/ month</sup>'}}>
                                                </h2>
                                                <ul className="plan-list">
                                                    {splitDescription(phonePlans[0].description).map((line, index) => (
                                                        <li key={index}>{line}</li>
                                                    ))}
                                                </ul>
                                                <Link to="/home-phone">
                                                <span className="a-link-bold">
                                                    View Phone options
                                                </span>
                                                </Link>
                                            </div>
                                        </label>
                                    </div>
                                )
                            ))}
                                </div>
                                </div>
                                </section>

                                <ServiceAddress
                                title="Check your service address"
                                />

                                <section className="home-section2">
                                <div className="container mx-auto px-4">
                                <h2 className="h2 text-center mx-auto">Same internet as the big guys, only less expensive</h2>
                            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <img src={internetImg2} alt="internet" className="home-sec2-img" />
                                <div className="home-sec2-content">
                                    <h3 className="h3">Be different like a Purple Cow</h3>
                                    <p className="p2">We are unique and different in the industry. Some would say we are the cow that sticks out. We keep our prices simple and that makes you a winner.</p>
                                    <p className="p2">Purple Cow Internet has been rated best internet provider in Halifax by google reviews and The Coast. We have also won many awards including best new business of the year by the Halifax Chamber of Commerce.</p>
                                </div>
                            </div> */}
                            <VideoSection
                                title="What makes Purple Cow different?"
                                paragraph1="We took a look at all the reasons you might hate a provider and set out to fix all of those. "
                                paragraph2="For starters we do not offer promotions with prices going up after a year. We do not charge you monthly for a wireless router or any of your equipment and finally when you call Purple Cow you will not have to wait on hold. A real person from Nova Scotia will pick up right away and will be happy to help out. "
                                paragraph3="Our goal is to continuously improve and become the best internet provider not only in Halifax, Nova Scotia however right across the Maritimes."
                            />
                        </div>
                </section>

                <section className="tv-section1">
                    <div className="container mx-auto px-4">
                        <h2 className="h2 mb-2">Want to add TV? No problem</h2>
                        <p className="p2 mb-5">Just download the app</p>
                        {/* <img src={tvImg1} alt="TV" className="tv-banner-img" /> */}
                    </div>
                </section>

                <section className="tv-section2">
                    <div className="container mx-auto px-4">
                        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="section2-text">
                                <h3 className="h3 h3-small">Download the Purple Cow TV app and watch on your existing
                                    device</h3>
                                <p className="p2">We designed our app to work on Apple, Android and Fire Stick's software. Also soon you will also be able to enjoy it on your computer browser. All you need is just a username and password. </p>
                                <p className="p2 large-view-show">Pause, Start, Record live TV all to the cloud so it is available on any of your devices for later.</p>
                                {showDetails3 && (
                                    <p className="p2">Pause, Start, Record live TV all to the cloud so it is available on any of your devices for later.</p>
                                )}
                                <p className={showDetails3 ? 'p2 learn-more-mobile show-detail' : 'p2 learn-more-mobile '} onClick={() => setShowDetails3(!showDetails3)} >
                                    {showDetails3 ? 'Hide Details' : 'Learn More'}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="#fbbd69"
                                        strokeWidth={2}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </p>
                                <Link className="btn-bg-new mt-5" to="/tv/">View TV details</Link>
                            </div>
                            <div className="">
                                <img src={tvImg3} alt="TV" className="tv-sec2-img" />
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="home-section3">
                    <div className="container mx-auto px-4">
                        <h2 className="h2 text-center text-white">Maybe add home phone?</h2>
                        <div className="max-width1000 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <img src={phoneStand} alt="phone" className="phone-stand" />
                            <div className="home-sec3-content">
                                <h3 className="h3 text-white">Home phone with all the features and of course you keep your current number   </h3>
                                <p className="p2 text-white">Your phone package comes standard with all the features including caller ID, call forwarding, three way calling, voicemail to email and much much more.</p>
                                <Link className="border-btn mt-5" to="/home-phone/">View Phone details</Link>
                            </div>
                        </div>
                    </div>
                </section> */}




                <RatingReviewHome
                    title="We are the highest rated internet provider in Nova Scotia"
                    subtitle="nothing wrong bragging a little :)"
                />

                {/* <ServiceAddress
                    title="Thinking about switching? Get started"
                /> */}

                <section className="home-section4">
                    <div className="container mx-auto px-4">
                        <div className="max-width1000 grid grid-cols-1 md:grid-cols-2 gap-4">
                            <img src={mapImg} alt="map" className="map-img" />
                            <div className="home-sec4-content">
                                <h3 className="h3">Purple Cow's service is in Nova Scotia, Prince Edward Island & Newfoundland</h3>
                                <p className="p2">We cover the majority of the above provinces. If you have any questions on serviceablity please check your address here online or shoot us a message.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <CommonQuestionHome />
                <HaveQuestion closepopup={setMessagePopup} />

                <div
                    dangerouslySetInnerHTML={{
                        __html: '<!-- TODO: hide this.. https://www.hidden-treasure.purplecowinternet.com -->'
                    }}
                />

                <LastSection title="Final note. We think you're awesome" />

                <div className="contact-us-parent">
                    <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                        <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                    </div>
                </div>
                {messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
            </LayoutNew>
        </div>
    );
};

export default FrontHomePage;